<template lang="pug">
  include /mixins.pug
  +b.g-container.--fit_md
    +b.i-card
      +b.ds-panel.--space_xl.--space_2xl-xl
        +e.element.--offset_bottom
          +b.P.ds-caption.--color_white.--appearance_center-till-md.--size_lg.--size_xl-xl.--bold {{ _('Вхід') }}
      +b.i-divider
        +e.content.--variant_1
      //- +b.ds-panel.--space_xl.--space_2xl-xl
      //-   +e.element.--offset_vertical
      //-     +b.g-row.--appearance_spaced.--justify_center.--justify_between-md.--align_center
      //-       +b.g-cell.g-cols.--5-md
      //-         +b.P.ds-caption.--color_white.--appearance_center-till-md.--size_md.--size_2md-md.--size_2lg-xl.--lh_2lg-xl
      //-           +b.A.ds-link.--size_md.--size_2md-md.--size_2lg-xl.--color_main.--inline(:href="isProduction ? '' : bankidUrl") {{ _('Швидка реєстрація') }}
      //-           |&nbsp;{{ _('за допомогою BankID НБУ') }}
      //-       +b.g-cell.is-disable.is-visible-md
      //-         +b.ds-inliner
      //-           +e.body
      //-             +b.ds-caption.--size_md.--color_main
      //-               +b.I.icon-arrow-right
      //-       +b.g-cell.g-cols.--5-md
      //-         +b.g-row.--appearance_spaced.--justify_center.--justify_end-md.--align_center
      //-           +b.g-cell.g-cols.--8-xs.--9-sm
      //-             a(:href="isProduction ? '' : bankidUrl")
      //-               img.pointer(:src="'/static/img/BankID.svg'")
      //- +b.i-divider
      //-   +e.content
      //-     +e.SPAN.text {{ _("або") }}
      +b.ds-panel.--space_xl.--space_2xl-xl
        +e.element.--offset_vertical
          +b.P.ds-caption.--color_white.--appearance_center-till-md.--size_md.--size_2md-md.--size_2lg-xl {{ _('Увійдить використовуючи авторізаційні дані') }}
      +b.VALIDATION-OBSERVER(
        @submit.prevent='prepareData'
        ref="validator"
        tag="form"
      )
        +b.g-row.--appearance_spaced.--space_xl
          cell(
            v-for='field in fields'
            :cols='field.cols || "12-xs 6-sm"'
            :key='field.name'
          )
            +b.TAG.d-control-input--variant_dark(
              autocomplete="off"
              :tag='field.tag || "d-control-static-input"'
              :name='field.name'
              :rules='field.rules'
              :ref='field.name'
              :type='field.type || "text"'
              :input-label='field.label'
              :hint="field.hint"
              v-model='formdata[field.name]'
            )
            modal-trigger(
              v-if="field.type === 'password'"
              name='PasswordReset',
              url='Auth/components/PasswordReset',
              :classes='["vm--modal_sm"]'
            )
              template(#default='{ open }')
                +b.ds-panel.--space_sm
                  +e.element.--offset_top
                    +b.A.ds-link.--color_main.--size_sm.--ta_right(
                      @click.prevent="open"
                    ) {{ _('нагадати пароль') }}
          +b.g-cell.g-cols.--12-xs
            +b.ds-panel.--space_md
              +e.element.--offset_top
                validation-provider(
                  slim
                  v-slot="{errors}"
                  name="agreementAcceptance"
                  :rules="{required: { allowFalse: false }}"
                )
                  +b.LABEL.d-control-checkbox.--variant_flex
                    +b.error-label(v-if="errors[0]") {{ errors[0] }}
                    +e.INPUT.element(
                      type='checkbox'
                      v-model='formdata.agreementAcceptance'
                    )
                    +e.SPAN.label
                    +e.SPAN.content
                      +e.SPAN.title
                        span {{ _('Ознайомлений з умовами') }} &nbsp;
                        +b.A.ds-link.--size_sm.--color_main.--inline(
                          :href="publicContractUrl"
                          target="_blank"
                          rel="noopener noreferrer"
                        ) {{ _('публічного договору про надання послуг') }}&nbsp;
                        span {{ _('та з умовами') }} &nbsp;
                        +b.A.ds-link.--size_sm.--color_main.--inline(
                          :href="secondPublicContractUrl"
                          target="_blank"
                          rel="noopener noreferrer"
                        ) {{ _('публічного договору про надання послуг для ФОП') }}
          validation-provider(
            slim
            v-slot="{errors}"
            name="nonFieldErrors"
          )
            +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
              +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default {{ errors[0] }}
          validation-provider(
            slim
            v-slot="{errors}"
            name="recaptcha"
          )
            +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
              +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default {{ errors[0] }}
          +b.g-cell.g-cols.--12-xs(v-if="nonFieldError.reason === 'REGISTRATION_EMAIL'")
            +b.g-row.--appearance_spaced
              +b.g-cell.g-cols.--12-xs
                +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default {{ _("Перейдіть по посиланню де вам буде надіслано листа для підтвердження пошти.") }} &nbsp;
                  +b.A.ds-link.--color_main.--size_2xs.--inline(:href="resendEmailUrl") {{ _("Відправити повторно") }}
        +b.ds-panel.--space_xl.--space_2xl-xl
          +e.element.--offset_top
            +b.g-row.--appearance_spaced.--justify_center.--justify_end-sm.--space_xl.--align_center
              +b.g-cell
                +b.A.ds-link.--color_main.--size_sm.--bold(
                  :href="registerUrl"
                ) {{ _('Зареєструватися') }}
              +b.g-cell.g-cols.--12-xs.--6-sm
                +b.BUTTON.control-button.--rounded(@click.prevent="prepareData")
                  +e.element
                    +e.SPAN.text {{ _('Увійти') }}
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser, getErrorField } from '@app/Forms/FormMixin'
import { userLoginResource } from '@api/auth.service'
import { prefixLanguage } from '@utils/urls'

export default {
  name: 'AuthLogin',

  mixins: [FormMixin],

  props: {
    registerUrl: {},
    bankidUrl: {},
    resendEmailUrl: {},
  },

  data() {
    return {
      captchaConfig: {
        isActive: true,
        action: 'staff',
      },
      publicContractUrl: window.publicContractUrl,
      secondPublicContractUrl: window.secondPublicContractUrl,
      nonFieldError: {},
      // isProduction: 'https://kupypai.com.ua' === window.location.origin,
      formdata: {
        agreementAcceptance: false,
      },
      fields: [
        {
          name: 'login',
          rules: 'required|emailMethod',
          label: this._('Ел. пошта'),
          hint: this._('Електронна пошта використовується як вхід при авторизації'),
        },
        {
          name: 'password',
          type: 'password',
          rules: 'required|max:32|min:8',
          label: this._('Пароль'),
        },
      ],
    }
  },

  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      // for displaying error reason
      this.nonFieldError = getErrorField(errors, 'nonFieldErrors')
      this.$refs.validator.setErrors(parsed)
    },

    async prepareData() {
      const isValid = await this.$refs.validator.validate()

      if (!isValid) return

      this.submit(isValid, this.formdata)
    },

    send(data) {
      this.nonFieldError = {}

      return userLoginResource.execute({}, data).then(() => {
        const nextUrl = this.getNextUrl()
        const profileUrl = prefixLanguage('/profile/map/')

        window.location = nextUrl || profileUrl

        window.dataLayer.push({
          event: 'formSuccess',
          eventCategory: 'singIn',
        })
      })
    },

    getNextUrl() {
      const params = new URLSearchParams(window.location.search)
      const redirect = params.get('next')

      return redirect
    },
  },
}
</script>
