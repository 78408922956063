<template lang="pug">
  include /mixins.pug
  +b.relative
    +b.i-preloader.--absolute.--variant_1(
      :class="{ 'is-active': isLoad, 'i-preloader--variant_3': isRegister }"
    )
      +e.item.--size_md
    +b.VALIDATION-OBSERVER(
      @submit.prevent='prepareData',
      ref="validator"
      tag="form"
    )
      +b.g-row.--appearance_spaced.--space_xl
        +b.g-cell.g-cols.--12-xs.--6-sm
          d-control-static-input(
            autocomplete="off"
            :input-class="fieldClasses.input"
            name="firstName"
            :input-label="labels.firstName"
            v-model="formdata.firstName"
            :rules="{ required: true, max: 255 }"
          )
        +b.g-cell.g-cols.--12-xs.--6-sm
          d-control-static-input(
            autocomplete="off"
            :input-class="fieldClasses.input"
            name="lastName"
            :input-label="labels.lastName"
            v-model="formdata.lastName"
            :rules="{ required: true, max: 255 }"
          )
        +b.g-cell.g-cols.--12-xs.--6-sm
          d-control-static-input(
            autocomplete="off"
            :input-class="fieldClasses.input"
            name="email"
            :input-label="labels.email"
            v-model="formdata.email"
            :rules="{ required: true, emailMethod: true }"
          )
        +b.g-cell.g-cols.--12-xs.--6-sm
          d-control-static-input(
            autocomplete="off"
            :input-class="fieldClasses.input"
            name="phone"
            :input-label="labels.phone"
            v-model="formdata.phone"
            :rules="{ required: true }"
            :mask="'+38 (999) 999 99 99'"
          )
        +b.g-cell.g-cols.--12-xs
          d-control-static-multiselect(
            :input-class="fieldClasses.input"
            :allow-empty="false"
            rules="required"
            name="geoPath"
            :input-label="labels.geoPath"
            :options="choices.division"
            v-model="formdata.geoPath"
            label="name"
          )
        +b.g-cell.g-cols.--12-xs
          +b.g-row.--appearance_spaced.--space_xl.--align_center
            +b.g-cell.g-cols.--12-xs.--6-sm
              ui-switch(
                v-model="formdata.withoutCadastre"
                field-class="ui-switch--variant_reversed"
              )
                template(slot="labelRight")
                  +b.SPAN.ds-caption.--size_xs(
                    :class="fieldClasses.caption"
                  ) {{ labels.withoutCadastre }}
            +b.g-cell.g-cols.--12-xs.--6-sm(v-if="!formdata.withoutCadastre")
              d-control-static-input(
                :input-class="fieldClasses.input"
                name="cadastre"
                :input-label="labels.cadastre"
                v-model="formdata.cadastre"
                :rules="{ required: true }"
                mask="9999999999:99:999:9999"
                :clear-mask-on-lost-focus="false"
              )
        +b.g-cell.g-cols.--12-xs
          +b.P.ds-caption.--size_sm.--size_md-xl.--bold(
            :class="fieldClasses.caption"
          ) {{ _("Чи перебуває земельна ділянка в оренді") }}
        +b.g-cell.g-cols.--12-xs
          +b.g-row.--appearance_spaced.--space_2xl-xl
            +b.g-cell.g-cols.--narrow-xs(v-for="item in choices.rent")
              +b.LABEL.d-control-radio.--variant_flex
                +e.INPUT.element(
                  type="radio"
                  :value="item.value"
                  v-model="formdata.inRent"
                )
                +e.SPAN.label(:class="{ 'd-control-radio__label--bordered': !isRegister }")
                +e.SPAN.content(:class="{ 'd-control-radio__content--color_dark': !isRegister }")
                  +e.SPAN.title(:class="{ 'is-active': formdata.inRent === item.value }") {{ item.title }}
        +b.g-cell.g-cols.--12-xs
          d-control-static-textarea(
            autocomplete="off"
            :input-class="['is-textarea', fieldClasses.input]"
            name="comment"
            :input-label="labels.comment"
            v-model="formdata.comment"
            rules="max:1000"
            rows="4"
          )
        +b.g-cell.g-cols.--12-xs
          validation-provider(
            slim
            v-slot="{ errors }"
            name="rules"
            :rules="{ required: { allowFalse: false } }"
          )
            +b.LABEL.d-control-checkbox.--variant_flex
              +b.error-label(v-if="errors[0]") {{ errors[0] }}
              +e.INPUT.element(
                type='checkbox'
                v-model='formdata.rules'
              )
              +e.SPAN.label(:class="{ 'd-control-checkbox__label--variant_bordered-grey': !isRegister }")
              +e.SPAN.content(:class="{ 'd-control-checkbox__content--color_dark': !isRegister }")
                +e.SPAN.title
                  span {{ _('Погоджуюсь з') }} &nbsp;
                  +b.A.ds-link.--size_sm.--color_main.--inline(
                    :href="policyUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                  ) {{ _('політикою конфіденційності та правилами сайту') }}
      validation-provider(
        slim
        v-slot="{errors}"
        name="nonFieldErrors"
      )
        +b.ds-panel.--space_xl.--space_3xl-xl(v-if="errors[0]")
          +e.element.--offset_top
            +b.P.ds-caption.--size_2xs.--color_primary-red.--lh_default {{ errors[0] }}
      +b.ds-panel.--space_xl.--space_3xl-xl
        +e.element.--offset_top
          +b.g-row.--appearance_spaced.--justify_center.--justify_end-sm.--space_xl.--align_center
            +b.g-cell.g-cols.--12-xs.--6-sm
              +b.BUTTON.control-button.--rounded(@click.prevent="prepareData")
                +e.element
                  +e.SPAN.text {{ _('Відправити заявку') }}
</template>

<script>
import { mapState } from 'vuex'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { saleRequestCreateResource } from '@api/sale-request.service'
import { geoDivisionsResource } from '@api/geo.service'
import { clearPhone } from '@utils/transformers'
import { AnswerMessage } from '@utils/submit'

export default {
  name: 'LandSaleRequestForm',

  props: {
    authUrl: {},
    policyUrl: {},
    isRegister: {},
  },

  mixins: [FormMixin],

  data() {
    return {
      captchaConfig: {
        isActive: true,
        action: 'staff',
      },
      formdata: {
        rules: false,
        inRent: false,
        withoutCadastre: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
      labels: {
        firstName: this._("Ім'я"),
        lastName: this._('Прізвище'),
        phone: this._('Телефон'),
        email: this._('Eл. пошта'),
        withoutCadastre: this._('Подати без кадастрового номеру'),
        cadastre: this._('Кадастровий номер'),
        comment: this._('Коментар'),
        geoPath: this._('Область, де розташована ділянка'),
      },
      choices: {
        rent: [
          { value: false, title: this._('Ні') },
          { value: true, title: this._('Так') },
        ],
        division: [],
      },
    }
  },

  computed: {
    ...mapState('users/auth', ['user']),

    fieldClasses() {
      return {
        input: this.isRegister ? 'd-control-input--variant_dark' : 'd-control-input--variant_5',
        caption: this.isRegister ? 'ds-caption--color_white' : 'ds-caption--color_dark-grey',
      }
    },
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(nval) {
        if (nval.determined) {
          const val = JSON.parse(JSON.stringify(nval.info))

          this.setUserInfo(val)
        }
      },
    },
  },

  mounted() {
    this.getDivisions()
  },

  methods: {
    setUserInfo(val) {
      this.formdata.firstName = val.firstName
      this.formdata.lastName = val.lastName
      this.formdata.email = val.user?.email
      this.formdata.phone = val.phone
    },

    getDivisions() {
      geoDivisionsResource.execute().then(res => {
        const { data: { items } } = res

        this.choices.division = items
      })
    },

    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)

      this.$refs.validator.setErrors(parsed)
    },

    async prepareData() {
      const isValid = await this.$refs.validator.validate()

      if (!isValid) return

      this.submit(isValid, this.formdata)
    },

    prepareFormdata(data) {
      const formdata = JSON.parse(JSON.stringify(data))

      formdata.phone = clearPhone(formdata.phone)

      formdata.requiredCadastre = !formdata.withoutCadastre

      delete formdata.withoutCadastre
      delete formdata.rules

      if (!formdata.requiredCadastre) {
        formdata.cadastre = null
      }

      formdata.geoPath = formdata.geoPath.path

      return formdata
    },

    resetForm() {
      this.formdata.rules = false
      this.formdata.inRent = false
      this.formdata.withoutCadastre = false
      this.formdata.cadastre = ''
      this.formdata.geoPath = null
      this.formdata.firstName = ''
      this.formdata.lastName = ''
      this.formdata.phone = ''
      this.formdata.email = ''
      this.formdata.comment = ''

      this.$nextTick(() => {
        this.$refs.validator.reset()
      })
    },

    async send(data) {
      const formdata = this.prepareFormdata(data)

      return saleRequestCreateResource.execute({}, formdata).then(() => {
        const message = {
          message: {
            title: this._('Land sale request success title'),
            text: this._('Land sale request success text'),
          },
          isLight: !this.isRegister,
        }

        if (this.isRegister) {
          this.resetForm()
        }

        AnswerMessage(message, this)

        this.$emit('success')
      })
    },
  },
}
</script>
