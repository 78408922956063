var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"g-container g-container--fit_md"},[_c('div',{staticClass:"i-card"},[_c('div',{staticClass:"ds-panel ds-panel--space_xl ds-panel--space_2xl-xl"},[_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_bottom"},[_c('p',{staticClass:"ds-caption ds-caption--color_white ds-caption--appearance_center-till-md ds-caption--size_lg ds-caption--size_xl-xl ds-caption--bold"},[_vm._v(_vm._s(_vm._('Вхід')))])])]),_vm._m(0),_c('div',{staticClass:"ds-panel ds-panel--space_xl ds-panel--space_2xl-xl"},[_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_vertical"},[_c('p',{staticClass:"ds-caption ds-caption--color_white ds-caption--appearance_center-till-md ds-caption--size_md ds-caption--size_2md-md ds-caption--size_2lg-xl"},[_vm._v(_vm._s(_vm._('Увійдить використовуючи авторізаційні дані')))])])]),_c('validation-observer',{ref:"validator",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.prepareData($event)}}},[_c('div',{staticClass:"g-row g-row--appearance_spaced g-row--space_xl"},[_vm._l((_vm.fields),function(field){return _c('cell',{key:field.name,attrs:{"cols":field.cols || "12-xs 6-sm"}},[_c('tag',{ref:field.name,refInFor:true,staticClass:"d-control-input d-control-input--variant_dark",attrs:{"autocomplete":"off","tag":field.tag || "d-control-static-input","name":field.name,"rules":field.rules,"type":field.type || "text","input-label":field.label,"hint":field.hint},model:{value:(_vm.formdata[field.name]),callback:function ($$v) {_vm.$set(_vm.formdata, field.name, $$v)},expression:"formdata[field.name]"}}),(field.type === 'password')?_c('modal-trigger',{attrs:{"name":"PasswordReset","url":"Auth/components/PasswordReset","classes":["vm--modal_sm"]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"ds-panel ds-panel--space_sm"},[_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_top"},[_c('a',{staticClass:"ds-link ds-link--color_main ds-link--size_sm ds-link--ta_right",on:{"click":function($event){$event.preventDefault();return open($event)}}},[_vm._v(_vm._s(_vm._('нагадати пароль')))])])])]}}],null,true)}):_vm._e()],1)}),_c('div',{staticClass:"g-cell g-cols g-cols--12-xs"},[_c('div',{staticClass:"ds-panel ds-panel--space_md"},[_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_top"},[_c('validation-provider',{attrs:{"slim":"","name":"agreementAcceptance","rules":{required: { allowFalse: false }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"d-control-checkbox d-control-checkbox--variant_flex"},[(errors[0])?_c('span',{staticClass:"error-label"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.agreementAcceptance),expression:"formdata.agreementAcceptance"}],staticClass:"d-control-checkbox__element",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formdata.agreementAcceptance)?_vm._i(_vm.formdata.agreementAcceptance,null)>-1:(_vm.formdata.agreementAcceptance)},on:{"change":function($event){var $$a=_vm.formdata.agreementAcceptance,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formdata, "agreementAcceptance", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formdata, "agreementAcceptance", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formdata, "agreementAcceptance", $$c)}}}}),_c('span',{staticClass:"d-control-checkbox__label"}),_c('span',{staticClass:"d-control-checkbox__content"},[_c('span',{staticClass:"d-control-checkbox__title"},[_c('span',[_vm._v(_vm._s(_vm._('Ознайомлений з умовами'))+" ")]),_c('a',{staticClass:"ds-link ds-link--size_sm ds-link--color_main ds-link--inline",attrs:{"href":_vm.publicContractUrl,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm._('публічного договору про надання послуг'))+" ")]),_c('span',[_vm._v(_vm._s(_vm._('та з умовами'))+" ")]),_c('a',{staticClass:"ds-link ds-link--size_sm ds-link--color_main ds-link--inline",attrs:{"href":_vm.secondPublicContractUrl,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm._('публічного договору про надання послуг для ФОП')))])])])])]}}])})],1)])]),_c('validation-provider',{attrs:{"slim":"","name":"nonFieldErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('div',{staticClass:"g-cell g-cols g-cols--12-xs"},[_c('p',{staticClass:"ds-caption ds-caption--size_2xs ds-caption--color_primary-red ds-caption--lh_default"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}])}),_c('validation-provider',{attrs:{"slim":"","name":"recaptcha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('div',{staticClass:"g-cell g-cols g-cols--12-xs"},[_c('p',{staticClass:"ds-caption ds-caption--size_2xs ds-caption--color_primary-red ds-caption--lh_default"},[_vm._v(_vm._s(errors[0]))])]):_vm._e()]}}])}),(_vm.nonFieldError.reason === 'REGISTRATION_EMAIL')?_c('div',{staticClass:"g-cell g-cols g-cols--12-xs"},[_c('div',{staticClass:"g-row g-row--appearance_spaced"},[_c('div',{staticClass:"g-cell g-cols g-cols--12-xs"},[_c('p',{staticClass:"ds-caption ds-caption--size_2xs ds-caption--color_primary-red ds-caption--lh_default"},[_vm._v(_vm._s(_vm._("Перейдіть по посиланню де вам буде надіслано листа для підтвердження пошти."))+" "),_c('a',{staticClass:"ds-link ds-link--color_main ds-link--size_2xs ds-link--inline",attrs:{"href":_vm.resendEmailUrl}},[_vm._v(_vm._s(_vm._("Відправити повторно")))])])])])]):_vm._e()],2),_c('div',{staticClass:"ds-panel ds-panel--space_xl ds-panel--space_2xl-xl"},[_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_top"},[_c('div',{staticClass:"g-row g-row--appearance_spaced g-row--justify_center g-row--justify_end-sm g-row--space_xl g-row--align_center"},[_c('div',{staticClass:"g-cell"},[_c('a',{staticClass:"ds-link ds-link--color_main ds-link--size_sm ds-link--bold",attrs:{"href":_vm.registerUrl}},[_vm._v(_vm._s(_vm._('Зареєструватися')))])]),_c('div',{staticClass:"g-cell g-cols g-cols--12-xs g-cols--6-sm"},[_c('button',{staticClass:"control-button control-button--rounded",on:{"click":function($event){$event.preventDefault();return _vm.prepareData($event)}}},[_c('div',{staticClass:"control-button__element"},[_c('span',{staticClass:"control-button__text"},[_vm._v(_vm._s(_vm._('Увійти')))])])])])])])])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"i-divider"},[_c('div',{staticClass:"i-divider__content i-divider__content--variant_1"})])}]

export { render, staticRenderFns }